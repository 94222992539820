import type { RootState } from 'widgets/onboarding/controller';
import { TotalStats } from 'common/types';
import { sum } from 'utils/common';

export const selectTotalStatsBySectionSelected = (
  state: RootState,
  participantCounts: { [key: string]: number },
  totalStats: { [key: string]: TotalStats }
): {
  co2: number;
  points: number;
  activities: number;
  participants: number;
} => {
  const { isLoading } = state.campaign;
  const { selectedFilter } = state.common;

  if (isLoading)
    return {
      co2: 0,
      points: 0,
      activities: 0,
      participants: 0,
    };

  if (selectedFilter === 'allGroups' || selectedFilter === 'all') {
    return {
      co2: totalStats.challenge?.co2 ?? 0,
      points: totalStats.challenge?.points ?? 0,
      activities: sum(totalStats.challenge?.activitiesCount ?? {}),
      participants: participantCounts.total ?? 0,
    };
  }

  return {
    co2: totalStats.sections[selectedFilter]?.co2 ?? 0,
    points: totalStats.sections[selectedFilter]?.points ?? 0,
    activities: sum(totalStats.sections[selectedFilter]?.activitiesCount ?? {}),
    participants: participantCounts.sections[selectedFilter] ?? 0,
  };
};
