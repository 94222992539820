import React from 'react';
import LanguageContext from 'common/LanguageContext';
import ConfigContext from 'common/ConfigContext';
import { extend, locale } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
extend(localizedFormat);

import enLang from 'resources/languages/en/widgets';
import jaLang from 'resources/languages/ja/widgets';
import noLang from 'resources/languages/no/widgets';

import nb from 'dayjs/locale/nb';
import en from 'dayjs/locale/en-gb';
import ja from 'dayjs/locale/ja';

import { ClientConfig } from 'configs/types';
import { useWidgetSelector } from '../../../../../common/hooks';

const locales = {
  nb: nb,
  en: en,
  ja: ja,
};

const languages = {
  en: enLang,
  ja: jaLang,
  no: noLang,
};

type Props = {
  readonly children?: React.ReactElement;
};

export default function App({ children }: Props): React.ReactElement {
  const language = useWidgetSelector(state => state.common.language);

  const languageData = languages[language];

  locale({ ...locales[languageData.format.locale], ...languageData.format });

  return (
    <ConfigContext.Provider
      value={process.env.CONFIG as unknown as ClientConfig}
    >
      <LanguageContext.Provider value={languageData}>
        {children}
      </LanguageContext.Provider>
    </ConfigContext.Provider>
  );
}
