import styled from 'styled-components';

export const ElementWrapper = styled.div`
  background-color: rgba(96, 125, 139, 0.12);
  width: 100%;
  position: relative;
  height: 4px;
  border-radius: 4px;
`;
export const ProgressWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

type ProgressProps = {
  $percent: number;
  $color: string;
};

export const Progress = styled.div<ProgressProps>`
  height: 4px;
  border-radius: 4px;
  background: ${props =>
    props.$percent > 0 ? props.$color : 'rgba(96, 125, 139, 0.04)'};
  width: ${props => `${props.$percent}%`};
`;
