import styled from 'styled-components';
import { Typ20 } from 'dc/Typ';
import ProB01 from 'dc/ProB01';

export const Wrapper = styled.div`
  width: 100%;
`;

export const MetaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

type ThemeProps = {
  $themeName?: 'light' | 'dark';
};

export const FromDate = styled(Typ20)<ThemeProps>`
  color: ${props =>
    props.$themeName === 'dark' ? 'rgba(255, 255, 255, 0.70)' : '#90a4ae'};
`;

export const ToDate = styled(Typ20)<ThemeProps>`
  color: ${props =>
    props.$themeName === 'dark' ? 'rgba(255, 255, 255, 0.70)' : '#90a4ae'};
`;

export const ProgressBar = styled(ProB01)<ThemeProps>`
  > div {
    background-color: ${props =>
      props.$themeName === 'dark'
        ? 'rgba(255, 255, 255, 0.12)'
        : 'rgba(96, 125, 139, 0.12)'};
  }

  > div > div {
    background-color: ${props =>
      props.$themeName === 'dark' ? 'rgba(255, 255, 255, 0.40)' : '#90a4ae'};
  }
`;
