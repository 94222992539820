import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Wrapper,
  MetaWrapper,
  FromDate,
  ToDate,
  ProgressBar,
} from './elements';
import Spa08 from 'dc/Spa08';

type ChaMeta08Props = {
  fromDate?: string;
  toDate?: string;
  className?: string;
  theme?: 'light' | 'dark';
  percent?: number;
};

function ChaMeta08(props: ChaMeta08Props): React.ReactElement {
  const isLoading = props.fromDate === props.toDate;

  return (
    <Wrapper className={props.className}>
      <MetaWrapper>
        <FromDate $themeName={props.theme}>
          {isLoading ? '-' : props.fromDate}
        </FromDate>
        <ToDate $themeName={props.theme}>
          {isLoading ? '-' : props.toDate}
        </ToDate>
      </MetaWrapper>
      <Spa08 />
      <ProgressBar $themeName={props.theme} percent={props.percent} />
    </Wrapper>
  );
}

ChaMeta08.propTypes = {
  className: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  percent: PropTypes.number,
};

export default ChaMeta08;
