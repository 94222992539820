import styled from 'styled-components';
import Ico16 from '../Ico16';
import { Typ20 } from '../Typ';
import LangPoM from '../LangPoM';

export const Wrapper = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Icon = styled(Ico16)`
  margin-right: 8px;
  color: ${props => (props.color ? props.color : '#004750')};
`;

export const Title = styled(Typ20)`
  color: ${props => (props.color ? props.color : '#004750')};
  cursor: pointer;
`;

export const LanguageSelector = styled(LangPoM)``;
