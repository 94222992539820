import React from 'react';
import { useWidgetSelector, useText } from 'common/hooks';
import SelectionComponent from 'widgets/onboarding/common/Selection';

type Props = {
  readonly className?: string;
};

export default function FilterSelection({
  className,
}: Props): React.ReactElement {
  const t = useText();
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);

  if (!hasFilter) {
    return null;
  }

  const campaign = useWidgetSelector(state => state.campaign.campaign);

  const sections = Object.keys(campaign.structure.sections).map(sectionKey => ({
    id: sectionKey,
    name: campaign.structure.sections[sectionKey].name,
  }));

  const options = {
    allGroups: t('widgets.championship.all', {
      term:
        campaign.sectionTerm === 'other'
          ? campaign.specifiedTerm
          : t(`campaign.section_terms.${campaign.sectionTerm || 'section'}`, {
              smart_count: 2,
            }),
    }),
    all: t('widgets.championship.all', {
      term: t(`widgets.challenge.team_term`),
    }),
    ...Object.assign(
      {},
      ...sections
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(s => ({
          [s.id]: s.name,
        }))
    ),
  };

  return <SelectionComponent className={className} options={options} />;
}
