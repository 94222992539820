import React from 'react';
import { useWidgetSelector, useWidgetDispatch } from 'common/hooks';
import { selectLeaderboardDataBySeriesSelected } from 'widgets/onboarding/widgets/championship/selectors';
import { arrowClicked } from 'widgets/onboarding/module';
import {
  useGetChampionshipParticipantCountsQuery,
  useGetChampionshipTotalStatsQuery,
} from 'widgets/onboarding/module/queries';

import Leaderboard from 'widgets/onboarding/common/Leaderboard';

type Props = {
  readonly className?: string;
};

export default function Visualization({
  className,
}: Props): React.ReactElement {
  const locale = useWidgetSelector(state => state.common.language);
  const championship = useWidgetSelector(
    state => state.championship.championship
  );
  const queryOptions = {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true,
    skip: false,
    refetchOnFocus: true,
  };

  const { data: partCounts } = useGetChampionshipParticipantCountsQuery(
    { campaigns: championship.campaigns },
    queryOptions
  );
  const { data: totalStats } = useGetChampionshipTotalStatsQuery(
    { campaigns: championship.campaigns },
    queryOptions
  );

  const graphData = useWidgetSelector(state =>
    selectLeaderboardDataBySeriesSelected(state, partCounts, totalStats)
  );
  const graphOffset = useWidgetSelector(state => state.common.graphOffset);
  const indicator = useWidgetSelector(state => state.common.indicator);
  const dispatch = useWidgetDispatch();

  return (
    <Leaderboard
      className={className}
      graphData={graphData}
      locale={locale}
      graphOffset={graphOffset}
      indicator={indicator}
      arrowClicked={offset => dispatch(arrowClicked(offset))}
    />
  );
}
