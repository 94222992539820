import React from 'react';
import { Wrapper, PositionLabel, Label } from './elements';
import { ICON } from 'resources/constants';

type Props = {
  readonly className?: string;
  readonly value: string;
  readonly name: string;
  readonly position: number;
  readonly percent: number;
  readonly icon?: string;
  readonly color: string;
};

export default function LBCo3b({
  className,
  value,
  name,
  position,
  percent,
  icon = 'icon-leaf',
  color,
}: Props): React.ReactElement {
  return (
    <Wrapper className={className}>
      <PositionLabel icon={ICON.crown}>{position}</PositionLabel>
      <Label
        name={name}
        points={value}
        icon={icon}
        percent={percent}
        iconColor={color}
        progressColor={color}
      />
    </Wrapper>
  );
}
