import { useState, useRef, useEffect } from 'react';

/**
 * Animates to any given input value from current value
 * @param {number|string} inputValue - Value to animate to
 * @param {number} duration - Duration of animation
 * @returns {number} - Current value
 */
export default function useCounter(inputValue, duration = 0) {
  const endValue = Number.isNaN(Number(inputValue)) ? 0 : inputValue;
  const [value, setValue] = useState(endValue);
  const startValue = useRef();
  const start = useRef();
  const end = useRef();
  const request = useRef();

  const animateFunc = t => {
    // If the counters are confused and time goes backwards, just wait for next frame
    if (t < start.current) {
      request.current = requestAnimationFrame(animateFunc);

      return 0;
    }

    const newValue =
      ((t - start.current) / (end.current - start.current)) *
        (endValue - startValue.current) +
      startValue.current;

    // If not done, schedule another update and write current value
    if (t < end.current) {
      setValue(newValue);
      request.current = requestAnimationFrame(animateFunc);
      // If time is up, write correct end value
    } else {
      setValue(endValue);
    }

    return 0;
  };

  useEffect(() => {
    startValue.current = value;

    if (value !== endValue) {
      if (duration > 0) {
        startValue.current = value;
        start.current = performance.now();
        end.current = start.current + duration;
        request.current = requestAnimationFrame(animateFunc);
      } else {
        setValue(endValue);
      }
    }
  }, [endValue]);

  return value;
}
