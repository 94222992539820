import styled from 'styled-components';
import Chart from '../ChartWrapper';

export const Wrapper = styled(Chart)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;

  text {
    text-anchor: middle;
    font-size: 14px;
  }

  text.value {
    fill: #37474f;
    font-size: 24px;
    font-weight: bold;
  }

  text.text {
    fill: #546e7a;
  }

  .arrow {
    cursor: pointer;
  }

  .arrow > path {
    stroke: white;
    stroke-width: 35px;
    stroke-opacity: 0.01;
  }

  #line {
    stroke: #eceff1;
  }

  .arrowdisabled {
    cursor: initial;
    fill: #cfd8dc;
  }
`;
