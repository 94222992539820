export default {
  total_saving: {
    co2: 'Totalt kgCO\u2082e spart',
    points: 'Totalt antall poeng opptjent',
  },
  total_participants: 'Totalt antall deltakere',
  total_activities: 'Aktiviteter logget',
  all: 'Alle %{term}',
  series_terms: {
    series: 'serier',
    company_size: 'bedriftsstørrelser',
    cities: 'byer',
    counties: 'fylker',
    industries: 'næringer',
  },
  campaign_terms: {
    campaigns: 'kampanjer',
    schools: 'skoler',
    companies: 'bedrifter',
  },
  days_until_start:
    '%{smart_count} dag til mesterskapet starter |||| %{smart_count} dager til mesterskapet starter',
  now_over: 'Mesterskapet er nå over',
  average: {
    co2: 'gjennomsnittlig kgCO\u2082e spart per person',
    co2_and_participation:
      'gjennomsnittlig kgCO\u2082e per deltaker OG minst %{requiredParticipation}% deltakelse nådd',
    points: 'Gjennomsnittlig poeng opptjent per person',
  },
  not_reached_participation:
    'ennå ikke nådd %{requiredParticipation}% deltakelsesrate, som kreves for å vinne.',
};
