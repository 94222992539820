import styled, { css } from 'styled-components';
import { Typ61 } from 'dc/Typ';
import { selectionWrapperWidth } from 'widgets/onboarding/common/Selection/elements';

type WrapperProps = {
  isMobile?: boolean;
  hasFilter?: boolean;
};

type MobileProps = {
  isMobile?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.hasFilter || props.isMobile ? 'flex-start' : 'center'};
  margin-right: 16px;
  width: calc(100% - ${selectionWrapperWidth});
  ${props =>
    props.isMobile &&
    css`
      width: 100%;
    `}
`;

export const TextWrapper = styled.div<MobileProps>`
  display: flex;
  max-width: 100%;
  width: 100%;
  ${props =>
    props.isMobile &&
    css`
      flex-direction: column;
    `};
`;

export const SeparatorHeader = styled(Typ61)`
  margin: 0 0 16px;
`;

const OrgCampaignHeader = styled(Typ61)<MobileProps>`
  margin: 0 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;

  ${props =>
    props.isMobile &&
    css`
      max-width: 100%;
      font-size: 20px;
      line-height: 24px;
    `}
`;

export const OrgHeader = styled(OrgCampaignHeader)<MobileProps>`
  font-weight: bold;

  ${props =>
    props.isMobile &&
    css`
      margin-bottom: 4px;
    `}
`;

export const CampaignHeader = styled(OrgCampaignHeader)<MobileProps>`
  ${props =>
    props.isMobile &&
    css`
      &.campaign {
        margin-bottom: 8px;
      }
    `}
`;
