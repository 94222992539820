import styled from 'styled-components';
import IcoW01 from '../IcoW01';
import PoM000 from '../PoM000';
import { Typ30 } from '../Typ';

export const DropdownWrapper = styled(PoM000)`
  width: 100%;
  padding: 16px 0;
  max-height: 208px;
  overflow: scroll;
  position: absolute;
  top: 50px;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const ElementWrapper = styled.div`
  width: 100%;
  position: relative;
  display: ${props => (props.disabled ? 'none' : 'block')};
`;

export const Option = styled.div`
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #546e7a;
  cursor: pointer;
  &:hover {
    background: #f4f7f9;
  }
`;

export const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  transition: border-color 0.25s;
  background-color: transparent;
  cursor: pointer;
`;

export const TextField = styled(Typ30)`
  padding: 12px 16px;
  color: #546e7a;
  flex-grow: 1;
  background-color: transparent;
  border-bottom: 1px solid #cfd8dc;
`;

export const DropdownArrow = styled(IcoW01)`
  cursor: pointer;
`;
