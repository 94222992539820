import styled from 'styled-components';
import { Typ30, Typ60 } from '../Typ';
import Ico24 from '../Ico24';

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 68px;
`;
export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CaptionText = styled(Typ30)`
  color: ${props => (props.theme === 'dark' ? '#FFFFFF' : '#263238')};
`;

export const NumberText = styled(Typ60)`
  color: ${props => (props.theme === 'dark' ? '#FFFFFF' : '#263238')};
`;
export const Icon = styled(Ico24)`
  color: ${props => props.color};
  margin: 8px 8px 8px 0;
`;
