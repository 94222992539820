import { createSlice } from '@reduxjs/toolkit';
import { WidgetCampaignState } from './types';
import * as thunks from './thunks';

const initialState: WidgetCampaignState = {
  isLoading: true,
  campaign: null,
};

export const { fetchCampaignData } = thunks;

export const widgetCampaignSlice = createSlice({
  name: 'widgetCampaign',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchCampaignData.fulfilled, (state, action) => {
        state.campaign = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCampaignData.rejected, state => {
        state.isLoading = false;
      });
  },
});

export default widgetCampaignSlice.reducer;
