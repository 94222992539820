import React, { useEffect } from 'react';
import { useWidgetSelector, useWidgetDispatch } from 'common/hooks';
import { useParams } from 'react-router-dom';
import { HeaderWrapper, Loader } from './elements';
import { fetchCampaignData } from 'widgets/onboarding/widgets/challenge/module';

import Legend from './Legend';
import Visualization from './Visualization';
import Header from './Header';
import Stats from './Stats';
import SeriesSelection from './FilterSelection';

export default function ChallengeWidget(): React.ReactElement {
  const { challengeKey } = useParams();
  const dispatch = useWidgetDispatch();

  const isLoading = useWidgetSelector(state => state.campaign.isLoading);
  const isMobile = useWidgetSelector(state => state.common.isMobile);
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);

  useEffect(() => {
    if (isLoading && challengeKey) {
      dispatch(fetchCampaignData(challengeKey));
    }
  }, [isLoading, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HeaderWrapper isMobile={isMobile} hasSeries={hasFilter}>
        <Header />
        <SeriesSelection />
      </HeaderWrapper>
      <Stats />
      <Visualization />
      <Legend />
    </>
  );
}
