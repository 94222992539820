import type { RootState } from 'widgets/onboarding/controller';
import { TotalStats } from 'common/types';

const sum = (s, v) => s + v;

export const selectTotalStatsBySeriesSelected = (
  state: RootState,
  participantCounts: { [key: string]: number },
  totalStats: { [key: string]: TotalStats }
): {
  co2: number;
  points: number;
  activities: number;
  participants: number;
} => {
  const { isLoading, championship } = state.championship;
  const { selectedFilter } = state.common;

  if (isLoading)
    return {
      co2: 0,
      points: 0,
      activities: 0,
      participants: 0,
    };

  const all = selectedFilter === 'allGroups' || selectedFilter === 'all';

  const campaigns = championship.campaigns.filter(
    campaign => all || campaign.series?.id === selectedFilter
  );

  const filterCampaigns = ([key]: [string, never]) =>
    all || campaigns.some(campaign => campaign.id === key);

  return {
    co2: Object.entries(totalStats || {})
      .filter(filterCampaigns)
      .map(([, campaign]) => campaign['co2'] || 0)
      .reduce<number>(sum, 0),
    points: Object.entries(totalStats || {})
      .filter(filterCampaigns)
      .map(([, campaign]) => campaign['points'] || 0)
      .reduce<number>(sum, 0),
    activities: Object.entries(totalStats || {})
      .filter(filterCampaigns)
      .flatMap(([, campaign]) =>
        Object.values(campaign['activitiesCount'] || {})
      )
      .reduce<number>(sum, 0),
    participants: Object.entries(participantCounts || {})
      .filter(filterCampaigns)
      .map(([, campaign]) => campaign)
      .reduce<number>(sum, 0),
  };
};
