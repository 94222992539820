import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, Text, Icon } from './elements';

type PoMIt03Props = {
  className?: string;
  onClick: () => void;
  selected: boolean;
  text: string;
};

function PoMIt03(props: PoMIt03Props): React.ReactElement {
  return (
    <Wrapper classname={props.className} onClick={props.onClick}>
      <Text>{props.text}</Text>
      <Icon selected={props.selected} icon={'icon-check'} />
    </Wrapper>
  );
}

PoMIt03.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  text: PropTypes.string,
};

export default PoMIt03;
