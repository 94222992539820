import styled from 'styled-components';

import Btn022B from 'dc/Btn022B';
import PoM000 from 'dc/PoM000';
import Spa16 from 'dc/Spa16';
import PoMIt03 from 'dc/PoMIt03';

export const ComponentWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0px;
  padding: 0px;
`;

export const Popover = styled(PoM000)`
  position: absolute;
  left: ${props =>
    props.popupPosition.indexOf('left') !== -1 ? '-126px' : '0px'};
  top: ${props =>
    props.popupPosition.indexOf('below') !== -1 ? '36px' : '-166px'};
  background: #ffffff;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Button = styled(Btn022B)`
  background: transparent;
`;

export const Spacer = styled(Spa16)`
  background: transparent;
`;
export const MenuItem = styled(PoMIt03)``;
