import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from '../common/Layout';
import NotFound from '../common/NotFound';
import Championship from '../widgets/championship/App';
import Challenge from '../widgets/challenge/App';

export default function App(): React.ReactElement {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="championship/:championshipKey"
            element={<Championship />}
          />
          <Route
            path=":language/championship/:championshipKey"
            element={<Championship />}
          />
          <Route path="challenge/:challengeKey" element={<Challenge />} />
          <Route
            path=":language/challenge/:challengeKey"
            element={<Challenge />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}
