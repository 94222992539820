import React from 'react';
import * as PropTypes from 'prop-types';
import Spa08 from '../Spa08';
import {
  OuterWrapper,
  InnerWrapper,
  Icon,
  NumberText,
  CaptionText,
} from './elements';
import Cnt10 from '../Cnt10';

function Lab113B(props) {
  return (
    <OuterWrapper className={props.className}>
      <InnerWrapper>
        <Icon icon={props.icon} color={props.color} />
        <NumberText theme={props.theme}>
          {typeof props.number === 'number' ? (
            <Cnt10 decimals={props.decimals} animate locale={props.locale}>
              {props.number}
            </Cnt10>
          ) : (
            props.number
          )}
        </NumberText>
      </InnerWrapper>
      <Spa08 />
      <CaptionText theme={props.theme}>{props.caption}</CaptionText>
    </OuterWrapper>
  );
}

Lab113B.propTypes = {
  caption: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  number: PropTypes.node,
  theme: PropTypes.string,
  locale: PropTypes.string,
};
export default Lab113B;
