export const DATE_FORMAT = {
  DAY_MONTH_LONG: {
    en: 'DD. MMMM',
    no: 'DD. MMMM',
    ja: 'MMMMDD日',
  },
  DAY_MONTH_SHORT: {
    en: 'DD. MMM',
    no: 'DD. MMM',
    ja: 'MMMMDD日',
  },
  DAY_MONTH_TIME_SHORT: {
    en: 'DD. MMM, HH:mm',
    no: 'DD. MMM [kl.] HH:mm',
    ja: 'MMMMDD日 HH:mm',
  },
};
