import React, { useState, Children, useRef } from 'react';
import { Swiper } from 'swiper/react';
import 'swiper/css';

import { Wrapper, DotWrapper, Dot, Spacer } from './elements';

type Props = {
  readonly className?: string;
  readonly children: React.ReactNode;
};

export default function CarMob01({
  children,
  className,
}: Props): React.ReactElement {
  const [currentIndex, setCurrentIndex] = useState(0);
  const swiperRef = useRef<any>(); // Without this, the line with slideTo throws some ts errors. This is not ideal, but I was unable to find any types being exported from the swiper package

  return (
    <Wrapper className={className}>
      <Swiper
        slidesPerView={1}
        onSlideChange={slide => setCurrentIndex(slide.activeIndex)}
        onSwiper={instance => {
          swiperRef.current = instance;
        }}
        style={{ width: '90vw' }}
      >
        {children}
      </Swiper>
      {Children.count(children) > 1 && (
        <div>
          <Spacer />
          <DotWrapper>
            {Children.map(children, (_, i) => (
              <Dot
                key={i} // eslint-disable-line react/no-array-index-key
                active={i === currentIndex}
                onClick={() => {
                  setCurrentIndex(i);
                  swiperRef?.current?.slideTo(i);
                }}
              />
            ))}
          </DotWrapper>
          <Spacer />
        </div>
      )}
    </Wrapper>
  );
}
