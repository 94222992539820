import React from 'react';
import { Wrapper, Image, Title, Body, Button } from './elements';

type Props = {
  readonly title: string;
  readonly body: string;
  readonly buttonText?: string;
  readonly imageSrc: string;
  readonly onClick?: () => void;
  readonly href?: string;
};

export default function NotFound({
  title,
  body,
  buttonText,
  imageSrc,
  onClick,
  href,
}: Props): React.ReactElement {
  return (
    <Wrapper>
      <Image src={imageSrc} />
      <Title>{title}</Title>
      <Body as={'p'}>{body}</Body>
      {buttonText && (
        <Button onClick={onClick} href={href}>
          {buttonText}
        </Button>
      )}
    </Wrapper>
  );
}
