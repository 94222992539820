import styled, { css } from 'styled-components';
import Load05 from 'dc/Load05';

type HeaderWrapperProps = {
  hasSeries?: boolean;
  isMobile?: boolean;
};

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  width: 100%;
  margin-top: 36px;

  ${props =>
    props.isMobile
      ? css`
          flex-direction: column;
          padding: 24px 17px 9px;
        `
      : css`
          padding: 0 32px 0 40px;
          justify-content: ${props.hasSeries ? 'space-between' : 'center'};
          align-items: center;
          height: 150px;
        `}
`;

export const Loader = styled(Load05)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
