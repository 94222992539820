import React from 'react';
import { useWidgetSelector, useWidgetDispatch } from 'common/hooks';
import { selectLeaderboardDataBySectionSelected } from 'widgets/onboarding/widgets/challenge/selectors';
import { arrowClicked } from 'widgets/onboarding/module';
import { useGetTotalStatsQuery } from 'widgets/onboarding/module/queries';
import Leaderboard from 'widgets/onboarding/common/Leaderboard';

type Props = {
  readonly className?: string;
};

export default function Visualization({
  className,
}: Props): React.ReactElement {
  const locale = useWidgetSelector(state => state.common.language);
  const campaign = useWidgetSelector(state => state.campaign.campaign);
  const queryOptions = {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true,
    skip: false,
    refetchOnFocus: true,
  };

  const totalStats = {
    challenge:
      useGetTotalStatsQuery(
        {
          challengeId: campaign.challenge.id,
          level: 'challenges',
        },
        queryOptions
      )?.data?.val() ?? 0,
    sections:
      useGetTotalStatsQuery(
        {
          challengeId: campaign.challenge.id,
          level: 'sections',
        },
        queryOptions
      )?.data?.val() ?? {},
    teams:
      useGetTotalStatsQuery(
        {
          challengeId: campaign.challenge.id,
          level: 'teams',
        },
        queryOptions
      )?.data?.val() ?? {},
  };

  const graphData = useWidgetSelector(state =>
    selectLeaderboardDataBySectionSelected(state, totalStats)
  );
  const graphOffset = useWidgetSelector(state => state.common.graphOffset);
  const indicator = useWidgetSelector(state => state.common.indicator);
  const dispatch = useWidgetDispatch();

  return (
    <Leaderboard
      className={className}
      graphData={graphData}
      locale={locale}
      graphOffset={graphOffset}
      indicator={indicator}
      arrowClicked={offset => dispatch(arrowClicked(offset))}
    />
  );
}
