import React from 'react';
import * as PropTypes from 'prop-types';
import { ElementWrapper, ProgressWrapper, Progress } from './elements';

type ProB01Props = {
  className?: string;
  onClick?: () => void;
  color?: string;
  percent: number;
  children?: React.ReactElement;
};

function ProB01({ className, onClick, color, percent, children }: ProB01Props) {
  return (
    <ElementWrapper className={className} onClick={onClick}>
      <ProgressWrapper>
        <Progress $color={color} $percent={percent < 0 ? 0 : percent} />
      </ProgressWrapper>
      {children}
    </ElementWrapper>
  );
}

ProB01.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  onClick: PropTypes.func,
  percent: PropTypes.number,
};

export default ProB01;
