import type { RootState } from 'widgets/onboarding/controller';
import { GraphData } from 'widgets/onboarding/types';
import { TotalStats } from 'common/types';
import { getIndicator } from 'utils/common';

export const selectLeaderboardDataBySectionSelected = (
  state: RootState,
  totalStats: { [key: string]: TotalStats }
): GraphData[] => {
  const { isLoading, campaign } = state.campaign;
  const { selectedFilter } = state.common;

  if (isLoading) return [];

  const sectionKeys = Object.keys(campaign.structure.sections);
  const teams = sectionKeys.flatMap(sectionKey =>
    Object.keys(campaign.structure.sections[sectionKey].teams).map(teamKey => ({
      teamKey,
      sectionKey,
      name: campaign.structure.sections[sectionKey].teams[teamKey].name,
    }))
  );

  if (selectedFilter === 'allGroups') {
    return sectionKeys
      .map(sectionKey => ({
        id: sectionKey,
        value: getIndicator(
          totalStats.sections[sectionKey],
          campaign.indicator,
          'average'
        ),
        color: '#0099A9',
        text: campaign.structure.sections[sectionKey].name,
      }))
      .sort((a, b) => b.value - a.value)
      .map((entry, _, array) => ({
        ...entry,
        percent:
          array[0].value === 0 ? 0 : (entry.value / array[0].value) * 100,
      }));
  }

  return teams
    .filter(
      team => selectedFilter === 'all' || team.sectionKey === selectedFilter
    )
    .map(team => {
      const value = getIndicator(
        totalStats.teams[team.sectionKey]?.[team.teamKey],
        campaign.indicator,
        'average'
      );

      return {
        id: team.teamKey,
        value: value,
        color: '#0099A9',
        text: team.name,
      };
    })
    .sort((a, b) => b.value - a.value)
    .map((entry, _, array) => ({
      ...entry,
      percent: array[0].value === 0 ? 0 : (entry.value / array[0].value) * 100,
    }));
};
