import React from 'react';
import { useWidgetSelector } from 'common/hooks';
import { selectTotalStatsBySeriesSelected } from 'widgets/onboarding/widgets/championship/selectors';
import StatsComponent from 'widgets/onboarding/common/Stats';
import {
  useGetChampionshipParticipantCountsQuery,
  useGetChampionshipTotalStatsQuery,
} from 'widgets/onboarding/module/queries';

type Props = {
  readonly className?: string;
};

export default function Stats({ className }: Props): React.ReactElement {
  const championship = useWidgetSelector(
    state => state.championship.championship
  );
  const queryOptions = {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true,
    skip: false,
    refetchOnFocus: true,
  };

  const { data: partCounts } = useGetChampionshipParticipantCountsQuery(
    { campaigns: championship.campaigns },
    queryOptions
  );
  const { data: toteStats } = useGetChampionshipTotalStatsQuery(
    { campaigns: championship.campaigns },
    queryOptions
  );

  const totalStats = useWidgetSelector(state =>
    selectTotalStatsBySeriesSelected(state, partCounts, toteStats)
  );

  return <StatsComponent className={className} totalStats={totalStats} />;
}
