import { Campaign } from 'widgets/onboarding/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  filterSelected,
  setHasFilter,
  setNotFound,
  setIndicator,
} from 'widgets/onboarding/module';
import { databaseProvider } from 'providers/Database/reduxIndex';
import { ClientConfig } from 'configs/types';

const database = databaseProvider({
  fetch: fetch,
  baseUrl: (process.env.CONFIG as unknown as ClientConfig).firebase.databaseURL,
  ccmUrl: (process.env.CONFIG as unknown as ClientConfig).ccm.URL,
  language: 'no',
});

function getCampaign(challengeKey: string): Promise<{ campaign: Campaign }> {
  return database
    .getCampaignByChallengeId(challengeKey)
    .then(campaign => ({ campaign }));
}

export const fetchCampaignData = createAsyncThunk(
  'widgetCampaign/fetchCampaign',
  async (campaignKey: string, { dispatch }): Promise<Campaign | null> => {
    const { campaign } = await getCampaign(campaignKey);

    if (!campaign) {
      dispatch(setNotFound());

      return null;
    }
    dispatch(setIndicator(campaign.indicator));

    const hasMoreThanOneSection =
      Object.keys(campaign.structure.sections).length > 1;

    dispatch(setHasFilter(hasMoreThanOneSection));

    if (hasMoreThanOneSection) {
      dispatch(filterSelected('allGroups'));
    }

    return campaign;
  }
);
