export default {
  campaign_code_pdf: {
    start: 'Starter {{START-DATE}}',
    share: 'Del din miljøgevinst med oss!',
  },
  section_terms: {
    building: 'bygning |||| bygninger',
    department: 'avdeling |||| avdelinger',
    discipline: 'fagfelt |||| fagfelt',
    faculty: 'fakultet |||| fakulteter',
    school: 'skole |||| skoler',
    area: 'område |||| områder',
    city: 'by |||| byer',
    municipality: 'kommune |||| kommuner',
    county: 'fylke |||| fylker', //CCM uses 'county' not 'county_municipality'
    county_municipality: 'fylke |||| fylker',
    country: 'land |||| land',
    section: 'seksjon |||| seksjoner',
  },
};
