import React from 'react';
import { Wrapper, Name, Label } from './elements';

type LBLab1Props = {
  readonly className?: string;
  readonly name: string;
  readonly icon: string;
  readonly onClick: () => void;
  readonly iconColor: string;
  readonly points: number | string;
};

export default function LBLab1({
  className,
  name,
  icon,
  onClick,
  iconColor,
  points,
}: LBLab1Props) {
  return (
    <Wrapper className={className}>
      <Name>{name}</Name>
      <Label icon={icon} onClick={onClick} $iconColor={iconColor}>
        {points}
      </Label>
    </Wrapper>
  );
}
