import { Store } from '@reduxjs/toolkit';
import React from 'react';
import { Provider } from 'react-redux';
import { RootState } from 'widgets/onboarding/controller';
import ContainerApp from '../ContainerApp';

export default function WidgetApp({
  children,
  store,
}: {
  readonly children: React.ReactElement;
  readonly store: Store<RootState>;
}): React.ReactElement {
  return (
    <Provider store={store}>
      <ContainerApp>{children}</ContainerApp>
    </Provider>
  );
}
