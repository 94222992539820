import React, { useEffect } from 'react';
import { useWidgetSelector, useWidgetDispatch } from 'common/hooks';
import { useParams } from 'react-router-dom';
import { HeaderWrapper, Loader } from './elements';
import { fetchChampionshipData } from 'widgets/onboarding/widgets/championship/module';

import Legend from './Legend';
import Visualization from './Visualization';
import Header from './Header';
import Stats from './Stats';
import SeriesSelection from './FilterSelection';

export default function ChampionshipWidget(): React.ReactElement {
  const { championshipKey } = useParams();
  const dispatch = useWidgetDispatch();

  const isLoading = useWidgetSelector(state => state.championship.isLoading);
  const isMobile = useWidgetSelector(state => state.common.isMobile);
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);

  useEffect(() => {
    if (isLoading && championshipKey) {
      dispatch(fetchChampionshipData(championshipKey));
    }
  }, [isLoading, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HeaderWrapper isMobile={isMobile} hasSeries={hasFilter}>
        <Header />
        <SeriesSelection />
      </HeaderWrapper>
      <Stats />
      <Visualization />
      <Legend />
    </>
  );
}
