import styled from 'styled-components';
import { Typ10, Typ11 } from 'dc/Typ';
import LBLab2 from 'dc/LBLab2';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

export const BoldPosition = styled(Typ10)`
  width: 48px;
  text-align: center;
  display: inline-block;
  flex: none;
`;

export const Position = styled(Typ11)`
  width: 48px;
  text-align: center;
  display: inline-block;
  flex: none;
`;

export const Label = styled(LBLab2)`
  margin-right: 16px;
  flex-grow: 1;
  min-width: 0;
`;
