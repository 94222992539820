import { Typ20 } from '../Typ';
import Ico20 from '../Ico20';
import styled from 'styled-components';

export const Button = styled.button`
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Icon = styled(Ico20)`
  color: ${props => (props.color ? props.color : '#546E7A')};
  height: 22px;
`;

export const Text = styled(Typ20)`
  color: ${props => (props.color ? props.color : '#546E7A')};
  padding-right: 8px;
`;
