import { useContext } from 'react';
import dayjs from 'dayjs';

import LanguageContext from '../LanguageContext';

export default function useFormatDate() {
  const language = useContext(LanguageContext);

  return function format(date, dateFormat) {
    return dayjs(date).format(dateFormat[language.locale]);
  };
}
