import styled, { keyframes } from 'styled-components';

const loaderBounce = keyframes`
  0%, 80%, 100% {transform: scale(0);}
  40% {transform: scale(1.0);}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DotOne = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: 0 4px;
  background-color: ${props => (props.color ? props.color : '#90a4ae')};
  animation: ${loaderBounce} 1.4s infinite ease-in-out both;
  display: inline-block;
`;

export const DotTwo = styled(DotOne)`
  animation-delay: 0.16s;
`;

export const DotThree = styled(DotOne)`
  animation-delay: 0.32s;
`;
