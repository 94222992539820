import * as React from 'react';
import * as PropTypes from 'prop-types';
import useCounter from '../../common/hooks/useCounter';
import { formatNumber } from 'utils/common';

type Cnt10Props = {
  animate?: boolean;
  children?: string | number;
  decimals?: string | number;
  locale: string;
};

function Cnt10({
  animate,
  children,
  decimals,
  locale,
}: Cnt10Props): React.ReactElement {
  const value = useCounter(children, animate ? 500 : 0);

  return <span>{formatNumber(value, { precision: decimals, locale })}</span>;
}

Cnt10.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.any,
  decimals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locale: PropTypes.string.isRequired,
};

Cnt10.defaultProps = {
  decimals: 0,
};

export default Cnt10;
