import styled from 'styled-components';
import Wra16D from '../Wra16D';
import { Typ30 } from '../Typ';
import Ico20 from '../Ico20';

export const Wrapper = styled(Wra16D)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: #eceff1;
  }
`;
export const Text = styled(Typ30)`
  margin-right: 16px;
`;

type IconProps = {
  selected: boolean;
};

export const Icon = styled(Ico20)<IconProps>`
  margin-right: 16px;
  visibility: ${props => (props.selected ? 'visible' : 'hidden')};
`;
