import React from 'react';
import { useWidgetSelector } from 'common/hooks';
import Duration from 'widgets/onboarding/common/Layout/Duration';
import {
  Wrapper,
  SeparatorHeader,
  OrgHeader,
  CampaignHeader,
  TextWrapper,
} from './elements';

type Props = {
  readonly className?: string;
};

export default function Header({ className }: Props): React.ReactElement {
  const isMobile = useWidgetSelector(state => state.common.isMobile);
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);
  const campaign = useWidgetSelector(state => state.campaign.campaign);

  return (
    <Wrapper className={className} hasFilter={hasFilter} isMobile={isMobile}>
      <TextWrapper isMobile={isMobile}>
        <OrgHeader isMobile={isMobile}>{campaign.organisation.name}</OrgHeader>
        {!isMobile && <SeparatorHeader>{`\u00A0|\u00A0`}</SeparatorHeader>}
        <CampaignHeader isMobile={isMobile}>{campaign.name}</CampaignHeader>
      </TextWrapper>
      <Duration
        startText={'widgets.challenge.days_until_start'}
        endText={'widgets.challenge.now_over'}
        startDatetime={campaign.challenge.startDatetime}
        endDatetime={campaign.challenge.endDatetime}
      />
    </Wrapper>
  );
}
