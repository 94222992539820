import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ICON } from 'resources/constants';
import { getLanguageUrl } from 'utils/common';

import { showNavItemMenuClicked } from 'widgets/onboarding/module';

import { NavBar } from './elements';
import {
  useText,
  useLink,
  useWidgetSelector,
  useWidgetDispatch,
} from 'common/hooks';

type Props = {
  readonly className?: string;
  readonly popupPosition?: string;
  readonly color?: string;
};

export default function NavBar3({
  className,
  popupPosition = 'below-left',
  color = '#004750',
}: Props): React.ReactElement {
  const t = useText();
  const link = useLink();
  const navigate = useNavigate();

  const currentLanguage = useWidgetSelector(state => state.common.language);
  const showNavLanguageMenu = useWidgetSelector(
    state => state.common.showNavLanguageMenu
  );

  const dispatch = useWidgetDispatch();

  return (
    <NavBar
      className={className}
      icon={ICON.flashOn}
      color={color}
      title={t('widgets.navbar.powered_by')}
      onTitleClick={() => window.open(link('', { endpoint: 'www' }), '_blank')}
      popupPosition={popupPosition}
      languageSelectorExpanded={showNavLanguageMenu}
      onClick={() => dispatch(showNavItemMenuClicked())}
      languageClicked={({ language }: { language: string }) => {
        navigate(getLanguageUrl(currentLanguage, language));
        dispatch(showNavItemMenuClicked());
      }}
      language={currentLanguage}
    />
  );
}
