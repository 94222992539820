import styled from 'styled-components';
import NavBarComponent from 'widgets/onboarding/common/NavBar';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const NavBar = styled(NavBarComponent)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
