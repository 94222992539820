import React from 'react';
import { useWidgetSelector } from 'common/hooks';

import { Wrapper, InfoWrapper, Line, Text } from './elements';

type Props = {
  readonly className?: string;
  readonly mainText: string;
  readonly secondaryText?: string;
};

export default function Legend({
  className,
  mainText,
  secondaryText,
}: Props): React.ReactElement {
  const isMobile = useWidgetSelector(state => state.common.isMobile);

  return (
    <Wrapper className={className} isMobile={isMobile}>
      <InfoWrapper isMobile={isMobile}>
        <Line color={'#0099A9'} />
        <Text>{mainText}</Text>
      </InfoWrapper>
      {secondaryText && (
        <InfoWrapper isMobile={isMobile}>
          <Line color={'#00545D'} />
          <Text>{secondaryText}</Text>
        </InfoWrapper>
      )}
    </Wrapper>
  );
}
