export default {
  ModLogin: {
    email: 'メールアドレス',
    password: 'パスワード',
    register: '登録',
    account: 'アカウント',
    login: 'ログイン',
    send_magic_link: 'ログイン用のリンクを送信',
    name: '氏名',
    send: '送信',
    super: 'スーパー',
    or: 'または',
  },
  ModTerms: {
    privacy_policy: 'プライバシーポリシー',
    last_updated: '%{date}',
    accept: '承諾する',
    decline: '拒否する',
    delete: 'アカウントを削除',
    go_back: '戻る',
    are_you_sure: '本当ですか？',
    are_you_sure_body:
      '更新された規約を拒否すると、あなたのアカウントと関連するすべてのデータが削除されます。',
    terms_updated: '規約を更新しました',
    terms_of_use:
      'MARKDOWN:[利用規約とプライバシーポリシーを読み、理解しました。](https://www.ducky.eco/ja/terms)',
  },
  ModRegister: {
    i_have_read: '',
    terms_of_use: '利用規約とプライバシーポリシーを読み、理解しました。',
    or: 'または',
  },
  NavBar: {
    support: 'サポート',
    products: '製品一覧',
    find_tools: 'ツールを探す',
    logIn: 'ログイン',
    menu: 'メニュー',
    register: 'アカウント登録',
    contact_us: 'お問い合わせ',
    your_tools: 'ご利用いただけるツール',
    faq: 'よくある質問',
    ProMen2: {
      active_since: 'アカウント作成日：',
      log_out: 'ログアウト',
      profile: 'プロフィールを編集',
    },
  },
  network_status_notification:
    'オフラインです。 インターネットを確認して下さい。',
  error_boundary: {
    title: 'Oops! Something went wrong',
    body: 'Our detective couldn’t solve the case',
    button_text: 'try again',
  },
  not_found: {
    title: 'We cannot find this page',
    body: '',
    button_text: 'Go back to the timeline',
  },
};
