import styled, { css } from 'styled-components';
import { Typ35 } from 'dc/Typ';
import ChaMeta08 from 'dc/ChaMeta08';

type DetailsProps = {
  isMobile?: boolean;
};

export const Details = styled(Typ35)<DetailsProps>`
  opacity: 70%;
  ${props =>
    props.isMobile &&
    css`
      font-size: 14px;
      line-height: 21px;
    `}
`;

export const Progress = styled(ChaMeta08)`
  max-width: 832px;

  & > div > span {
    color: #007079;
    font-size: 14px;
  }

  & > div > div > div {
    background-color: #007079;
  }
`;
