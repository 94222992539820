import React from 'react';
import * as PropTypes from 'prop-types';
import chart from './chart';
import { Wrapper } from './elements';

function GraphChamp(props) {
  return (
    <Wrapper
      chart={chart}
      className={props.className}
      locale={props.locale}
      indicator={props.indicator}
      backgroundColor={props.backgroundColor}
      data={props.data}
      offsetNumber={props.offsetNumber}
      barSize={props.barSize}
    />
  );
}

GraphChamp.propTypes = {
  locale: PropTypes.string,
  indicator: PropTypes.string,
  backgroundColor: PropTypes.string,
  offsetNumber: PropTypes.number,
  barSize: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      color: PropTypes.string,
      text: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
};

export default GraphChamp;
