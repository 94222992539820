import React from 'react';
import useDimensions from 'react-cool-dimensions';
import { useWidgetSelector, useFormatNumber } from 'common/hooks';
import { GraphData } from 'widgets/onboarding/types';

import {
  Graph,
  GraphWrapper,
  Carousel,
  ListWrapper,
  ListItem,
  ListItemLeader,
  GraphButtonsWrapper,
  Arrow,
  CarouselDots,
  LeaderboardWrapper,
} from './elements';

import { getIndicatorConfig } from 'utils/common';
import { INDICATOR, ICON } from 'resources/constants';
import { SwiperSlide } from 'swiper/react';

type Props = {
  readonly className?: string;
  readonly graphData: GraphData[];
  readonly locale: string;
  readonly graphOffset: number;
  readonly indicator: string;
  readonly arrowClicked: (offset: number) => void;
};

const BAR_WIDTH = 48;
const BAR_MARGIN = 42;
const BAR_TOTAL_WIDTH = BAR_WIDTH + 2 * BAR_MARGIN;

export default function Leaderboard({
  className,
  graphData,
  locale,
  graphOffset,
  indicator,
  arrowClicked,
}: Props): React.ReactElement {
  const formatNumber = useFormatNumber();
  const { icon, color } = getIndicatorConfig(indicator);
  const { observe: ref, width } = useDimensions({
    onResize: ({ observe, unobserve }) => {
      // Triggered whenever the size of the target is changed...
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });
  const isMobile = useWidgetSelector(state => state.common.isMobile);
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);

  const barsOnScreen = Math.floor((width - 2 * BAR_MARGIN) / BAR_TOTAL_WIDTH);

  const pages = Math.ceil(graphData.length / barsOnScreen);
  const pageNumber = Math.ceil(graphOffset / barsOnScreen);

  const leftButtonDisabled = graphOffset === 0;
  const rightButtonDisabled = graphOffset + barsOnScreen >= graphData.length;

  function handledClick(disabled, offsetNumber) {
    if (disabled) return null;

    return () => arrowClicked(offsetNumber);
  }

  if (isMobile) {
    const leaderboardPages: GraphData[][] = [];
    const chunkLength = hasFilter ? 8 : 9;
    for (let i = 0; i < graphData.length; i += chunkLength) {
      const newArray = graphData.slice(i, i + chunkLength);
      leaderboardPages.push(newArray);
    }
    const heightOfListWrapper = `${chunkLength * 48}px`;
    const heightOfCarousel = hasFilter ? '447px' : '495px';

    return (
      <LeaderboardWrapper className={className}>
        <Carousel height={heightOfCarousel}>
          {leaderboardPages.map((leaderboardPage, pageIndex) => (
            <SwiperSlide key={`leaderPage${pageIndex + 1}`}>
              <ListWrapper height={heightOfListWrapper}>
                {leaderboardPage.map((data, index) =>
                  index + pageIndex * chunkLength === 0 ? (
                    <ListItemLeader
                      key={data.id}
                      value={formatNumber(
                        data.value,
                        indicator === INDICATOR.co2 ? 2 : 0
                      )}
                      name={data.text}
                      percent={data.percent}
                      position={index + 1 + pageIndex * chunkLength}
                      progressColor={data.color}
                      icon={icon}
                      color={color}
                    />
                  ) : (
                    <ListItem
                      key={data.id}
                      value={formatNumber(
                        data.value,
                        indicator === INDICATOR.co2 ? 2 : 0
                      )}
                      name={data.text}
                      percent={data.percent}
                      position={index + 1 + pageIndex * chunkLength}
                      progressColor={data.color}
                      icon={icon}
                      color={color}
                    />
                  )
                )}
              </ListWrapper>
            </SwiperSlide>
          ))}
        </Carousel>
      </LeaderboardWrapper>
    );
  }

  return (
    <LeaderboardWrapper className={className}>
      <GraphWrapper ref={ref}>
        <GraphButtonsWrapper>
          {pages > 1 && (
            <>
              <Arrow
                icon={ICON.keyboardArrowLeft}
                color={leftButtonDisabled ? '#d1d8dc' : '#607d8b'}
                onClick={handledClick(
                  leftButtonDisabled,
                  Math.max(0, graphOffset - barsOnScreen)
                )}
              />
              <CarouselDots
                currentSlideIndex={pageNumber + 1}
                slideCount={pages}
                theme={'light'}
                changeDot={index => {
                  arrowClicked(barsOnScreen * index);
                }}
              />
              <Arrow
                icon={ICON.keyboardArrowRight}
                color={rightButtonDisabled ? '#d1d8dc' : '#607d8b'}
                onClick={handledClick(
                  rightButtonDisabled,
                  Math.min(
                    graphOffset + barsOnScreen,
                    graphData.length - barsOnScreen
                  )
                )}
              />
            </>
          )}
        </GraphButtonsWrapper>
        <Graph
          indicator={indicator}
          locale={locale}
          data={graphData}
          onArrowClicked={arrowClicked}
          offsetNumber={graphOffset}
          backgroundColor={'#f4f7f9'}
          barSize={{
            width: BAR_WIDTH,
            margin: BAR_MARGIN,
          }}
        />
      </GraphWrapper>
    </LeaderboardWrapper>
  );
}
