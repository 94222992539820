import styled from 'styled-components';
import { Typ21 } from 'dc/Typ';
import Lab010 from 'dc/Lab010';

export const Wrapper = styled.div`
  display: flex;
`;

export const Name = styled(Typ21)`
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

type LabelProps = {
  $iconColor?: string;
};

export const Label = styled(Lab010)<LabelProps>`
  margin-left: 8px;

  > i {
    color: ${props => props.$iconColor || '#00ab97'};
  }
`;
