import React from 'react';
import { useWidgetSelector, useText } from 'common/hooks';
import SelectionComponent from 'widgets/onboarding/common/Selection';

type Props = {
  readonly className?: string;
};

export default function FilterSelection({
  className,
}: Props): React.ReactElement {
  const t = useText();
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);

  if (!hasFilter) {
    return null;
  }

  const championship = useWidgetSelector(
    state => state.championship.championship
  );

  const series = [...(championship.series || [])];

  const options = {
    allGroups: t('widgets.championship.all', {
      term: t(
        `widgets.championship.series_terms.${
          championship.seriesType || 'series'
        }`
      ),
    }),
    all: t('widgets.championship.all', {
      term: t(
        `widgets.championship.campaign_terms.${
          championship.organisationType || 'campaigns'
        }`
      ),
    }),
    ...Object.assign(
      {},
      ...series
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(s => ({
          [s.id]: s.name,
        }))
    ),
  };

  return <SelectionComponent className={className} options={options} />;
}
