import React from 'react';
import { useWidgetSelector, useWidgetDispatch } from 'common/hooks';
import { Wrapper, Select } from './elements';
import { filterSelected, toggleFilter } from 'widgets/onboarding/module';

type Props = {
  readonly className?: string;
  readonly options: { [key: string]: string };
};

export default function Selection({
  className,
  options,
}: Props): React.ReactElement {
  const selectedFilter = useWidgetSelector(
    state => state.common.selectedFilter
  );
  const filterExpanded = useWidgetSelector(
    state => state.common.filterExpanded
  );
  const isMobile = useWidgetSelector(state => state.common.isMobile);

  const dispatch = useWidgetDispatch();

  return (
    <Wrapper className={className} isMobile={isMobile}>
      <Select
        options={options}
        value={selectedFilter}
        onDropdownItemClick={({ optionKey: filterKey }) =>
          dispatch(filterSelected(filterKey))
        }
        onSelectClick={() => dispatch(toggleFilter())}
        dropdownVisible={filterExpanded}
        isMobile={isMobile}
      />
    </Wrapper>
  );
}
