import appContainer from '../appContainer';
import campaign from '../campaign';
import challenge from './challenge';
import championship from './championship';

export default {
  locale: 'ja',
  fullLocale: 'ja-JP',
  format: {
    locale: 'ja',
    weekStart: 1,
  },
  phrases: {
    appContainer,
    campaign,
    widgets: {
      challenge,
      championship,
      navbar: {
        powered_by: 'Powered by Ducky',
      },
    },
  },
};
