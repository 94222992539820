import styled from 'styled-components';

export const Wrapper = styled.div``;

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type DotProps = {
  active?: boolean;
};

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: ${({ active }) =>
    active ? 'rgba(96,125,139,0.70)' : 'rgba(96,125,139,0.12)'};
`;

export const Spacer = styled.div`
  height: 16px;
  background-color: transparent;
`;
