import styled, { css } from 'styled-components';
import TxtField15 from 'dc/TxtField15';

type MobileProps = {
  isMobile: boolean;
};

export const selectionWrapperWidth = '320px';

export const Wrapper = styled.div<MobileProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${selectionWrapperWidth};

  ${props =>
    props.isMobile &&
    css`
      justify-content: center;
      width: 100%;
      margin-top: 8px;
    `};
`;

export const Select = styled(TxtField15)<MobileProps>`
  ${props =>
    !props.isMobile &&
    css`
      max-width: 240px;
    `};
`;
