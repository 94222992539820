import styled, { css } from 'styled-components';
import { Typ20 } from 'dc/Typ';

type MobileProps = {
  isMobile?: boolean;
};

export const Wrapper = styled.div<MobileProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
  width: 100%;
  padding: 0 53px;

  ${props =>
    props.isMobile &&
    css`
      flex-direction: column;
      padding: 16px 32px 22px;
      align-items: flex-start;
    `}
`;

export const InfoWrapper = styled.div<MobileProps>`
  margin-left: ${props => !props.isMobile && '49px'};
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.isMobile && '10px'};
`;

type LineProps = {
  color: string;
};

export const Line = styled.div<LineProps>`
  height: 4px;
  width: 16px;
  border-radius: 3px;
  background-color: ${props => props.color};
  margin-right: 10px;
  flex-shrink: 0;
`;

export const Text = styled(Typ20)``;
