import React from 'react';
import * as PropTypes from 'prop-types';
import {
  primaryLanguage,
  secondaryLanguages,
  unfinishedLanguages,
  languageNames,
} from 'languages/config';
import {
  ComponentWrapper,
  Popover,
  Button,
  Spacer,
  MenuItem,
} from './elements';

import LangButton from '../Button';

const languages = [
  primaryLanguage,
  ...secondaryLanguages.filter(l => !unfinishedLanguages.includes(l)),
];

function LangPoM(props) {
  function onBlur(e) {
    const { currentTarget } = e;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        props.onClick();
      }
    }, 0);
  }

  return (
    <ComponentWrapper className={props.className} onBlur={onBlur}>
      <Button
        onClick={props.onClick}
        color={props.theme === 'dark' ? '#fff' : props.color}
        icon={'icon-public'}
      >
        {props.language.toUpperCase()}
      </Button>
      {props.expanded && (
        <Popover popupPosition={props.popupPosition}>
          <Spacer />
          {languages.map(language => (
            <LangButton
              key={language}
              onClick={() => {
                props.languageClicked({ language });
              }}
            >
              <MenuItem
                selected={props.language === language}
                text={languageNames[language]}
              />
            </LangButton>
          ))}
          <Spacer />
        </Popover>
      )}
    </ComponentWrapper>
  );
}

LangPoM.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  languageClicked: PropTypes.func,
  theme: PropTypes.string,
};

export default LangPoM;
