import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Icon, Text } from './elements';

function Btn022B(props) {
  return (
    <Button
      className={props.className}
      disabled={Boolean(props.disabled)}
      onClick={props.onClick}
      theme={props.theme}
    >
      <Text color={props.color} type={'button'}>
        {props.children}
      </Text>
      <Icon color={props.color} icon={props.icon} />
    </Button>
  );
}

Btn022B.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
};

export default Btn022B;
