export default {
  campaign_code_pdf: {
    start: '{{START-DATE}}に開始',
    share: '成果を共有しましょう！',
  },
  section_terms: {
    building: '建物',
    department: '部門',
    discipline: '分野',
    faculty: '学部',
    school: '学校',
    area: 'エリア',
    city: '市区町村',
    municipality: '市町村自治体',
    county: '県自治体', //CCM uses 'county' not 'county_municipality'
    county_municipality: '県自治体',
    country: '国',
    section: 'セクション',
  },
};
