import styled from 'styled-components';
import GraphChamp from 'dc/GraphChamp';
import LBCo3b from 'dc/LBCo3b';
import LBCo2b from 'dc/LBCo2b';
import CarMob01 from 'dc/CarMob01';
import IcoW01 from 'dc/IcoW01';
import CarNav01 from 'dc/CarNav01';

export const LeaderboardWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 0 22px;
`;

export const GraphWrapper = styled.div`
  height: 494px;
  background-color: #f4f7f9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

type ListWrapperHeight = {
  height: string;
};

export const ListWrapper = styled.div<ListWrapperHeight>`
  width: 95%;
  height: ${props => props.height};
`;

export const GraphButtonsWrapper = styled.div`
  width: 100%;
  padding: 0 22px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Arrow = styled(IcoW01)``;

export const CarouselDots = styled(CarNav01)``;

export const Graph = styled(GraphChamp)`
  overflow: hidden;
`;

type ListItemProps = {
  progressColor: string;
};

export const ListItem = styled(LBCo2b)<ListItemProps>`
  & > div > div > div > div {
    background-color: ${props => props.progressColor};
  }
`;

export const ListItemLeader = styled(LBCo3b)<ListItemProps>`
  & > div > div > div > div {
    background-color: ${props => props.progressColor};
  }
`;

type CarouselProps = {
  height: string;
};

export const Carousel = styled(CarMob01)<CarouselProps>`
  background-color: #f4f7f9;
  border-radius: 10px;
  height: ${props => props.height};
  padding: 12px 10px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;
