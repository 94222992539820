import { createSlice } from '@reduxjs/toolkit';
import { INDICATOR } from 'resources/constants';
import { WidgetCommonState } from './types';
import * as reducers from './reducers';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { ClientConfig } from 'configs/types';

initializeApp(
  process.env.NODE_ENV === 'test'
    ? ((JSON.parse(process.env.CONFIG) as unknown as ClientConfig)
        .firebase as FirebaseOptions)
    : ((process.env.CONFIG as unknown as ClientConfig)
        .firebase as FirebaseOptions)
);

export const initialState: WidgetCommonState = {
  widget: 'championship',
  indicator: INDICATOR.co2,
  filterExpanded: false,
  selectedFilter: 'all',
  hasFilter: true,
  graphOffset: 0,
  isMobile: false,
  language: 'no',
  showNavLanguageMenu: false,
  isNotFound: false,
};

export const widgetCommonSlice = createSlice({
  name: 'widgetCommon',
  initialState,
  reducers,
});

export const {
  filterSelected,
  toggleFilter,
  arrowClicked,
  setHasFilter,
  setIndicator,
  setNotFound,
  setLanguage,
  setIsMobile,
  showNavItemMenuClicked,
} = widgetCommonSlice.actions;

export default widgetCommonSlice.reducer;
