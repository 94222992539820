import styled from 'styled-components';
import Lab110 from 'dc/Lab110';
import LBLab2 from 'dc/LBLab2';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

export const PositionLabel = styled(Lab110)`
  margin: 0 6px;
  flex: none;

  > i {
    color: #ffc107;
  }

  > span {
    font-size: 12px;
  }
`;

export const Label = styled(LBLab2)`
  margin-right: 16px;
  flex-grow: 1;
  min-width: 0;
`;
