import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Wrapper,
  TitleWrapper,
  Icon,
  Title,
  LanguageSelector,
} from './elements';

function NavBar3(props) {
  return (
    <Wrapper className={props.className}>
      <TitleWrapper>
        <Icon icon={props.icon} color={props.color} />
        <Title color={props.color} onClick={props.onTitleClick}>
          {props.title}
        </Title>
      </TitleWrapper>
      <LanguageSelector
        language={props.language}
        expanded={props.languageSelectorExpanded}
        onClick={props.onClick}
        color={props.color}
        popupPosition={props.popupPosition}
        languageClicked={props.languageClicked}
      />
    </Wrapper>
  );
}

NavBar3.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onTitleClick: PropTypes.func,
  icon: PropTypes.string,
  language: PropTypes.string,
  languageSelectorExpanded: PropTypes.bool,
  color: PropTypes.string,
  popupPosition: PropTypes.string,
  languageClicked: PropTypes.func,
};

export default NavBar3;
