import type { RootState } from 'widgets/onboarding/controller';
import { GraphData } from 'widgets/onboarding/types';
import { TotalStats } from 'common/types';
import { getIndicator } from 'utils/common';

const sum = (s, v) => s + v;

export const selectLeaderboardDataBySeriesSelected = (
  state: RootState,
  participantCounts: { [key: string]: number },
  totalStats: { [key: string]: TotalStats }
): GraphData[] => {
  const { isLoading, championship } = state.championship;
  const { selectedFilter } = state.common;

  if (isLoading) return [];

  const allSeries = championship.series || [];

  const winningParticipationPercentage =
    Number(championship.winningParticipationPercentage) || 0;

  // If we compare series, compute weighted averages of campaigns
  if (selectedFilter === 'allGroups') {
    return allSeries
      .map(series => {
        const campaigns = championship.campaigns.filter(
          campaign => campaign.series?.id === series.id
        );
        const totalParticipants = campaigns
          .map(campaign => participantCounts?.[campaign.id] || 0)
          .reduce(sum, 0);
        const expectedParticipants = campaigns
          .map(campaign => campaign.structure.expectedParticipantCount || 0)
          .reduce(sum, 0);
        const participationRate =
          expectedParticipants > 0
            ? (totalParticipants / expectedParticipants) * 100
            : 0;

        const sumOfAccumulatedAveragesTimesParticipants = Object.entries(
          totalStats || {}
        )
          .filter(([campaignKey]) =>
            campaigns.some(campaign => campaign.id === campaignKey)
          )
          .map(
            ([campaignKey, scores]) =>
              participantCounts?.[campaignKey] *
              getIndicator(scores, championship.indicator, 'average')
          )
          .reduce(sum, 0);

        const value =
          totalParticipants > 0
            ? sumOfAccumulatedAveragesTimesParticipants / totalParticipants
            : 0;

        return {
          id: series.id,
          value,
          color:
            Math.round(participationRate) >= winningParticipationPercentage
              ? '#0099A9'
              : '#CFD8DC',
          text: series.name,
        };
      })
      .sort((a, b) => b.value - a.value)
      .map((entry, _, array) => ({
        ...entry,
        percent:
          array[0].value === 0 ? 0 : (entry.value / array[0].value) * 100,
      }));
  }

  const all = selectedFilter === 'all';

  return championship.campaigns
    .filter(campaign => all || campaign.series?.id === selectedFilter)
    .map(campaign => {
      const participationRate =
        (participantCounts?.[campaign.id] /
          campaign.structure.expectedParticipantCount || 0) * 100;
      const value = getIndicator(
        totalStats?.[campaign.id],
        championship.indicator,
        'average'
      );

      return {
        id: campaign.id,
        value: value,
        color:
          Math.round(participationRate) >= winningParticipationPercentage
            ? '#00545D'
            : '#0099A9',
        text: campaign.organisation.name,
      };
    })
    .sort((a, b) => b.value - a.value)
    .map((entry, _, array) => ({
      ...entry,
      percent: array[0].value === 0 ? 0 : (entry.value / array[0].value) * 100,
    }));
};
