import React from 'react';
import * as PropTypes from 'prop-types';
import { OuterWrapper, DotsWrapper, Dot } from './elements';

type CarNav01Props = {
  changeDot?: (index: number) => void;
  color?: string;
  className?: string;
  currentSlideIndex: number;
  slideCount: number;
  theme: string;
};

function CarNav01(props: CarNav01Props): React.ReactElement {
  function handleDotClick(index: number) {
    if (!props.changeDot) {
      return null;
    }

    return props.changeDot(index);
  }
  const dotCount = props.slideCount;
  const dotsArray = new Array(dotCount + 1).join('0').split('');

  const dots = dotsArray.map((dot, index) => (
    <Dot
      theme={props.theme}
      $isActive={index + 1 === props.currentSlideIndex}
      color={props.color}
      id={index.toString()}
      key={index} // eslint-disable-line react/no-array-index-key
      onClick={() => handleDotClick(index)}
      $noPointer={Boolean(!props.changeDot)}
    />
  ));

  return (
    <OuterWrapper className={props.className}>
      <DotsWrapper>{dots}</DotsWrapper>
    </OuterWrapper>
  );
}

CarNav01.propTypes = {
  changeDot: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  currentSlideIndex: PropTypes.number,
  slideCount: PropTypes.number,
  theme: PropTypes.string,
};

export default CarNav01;
