import React from 'react';
import * as PropTypes from 'prop-types';
import { Typ32 } from '../Typ';
import Ico20 from '../Ico20';
import { ElementWrapper } from './elements';

function Lab110(props) {
  return (
    <ElementWrapper onClick={props.onClick} className={props.className}>
      <Ico20 icon={props.icon} />
      <Typ32>{props.children}</Typ32>
    </ElementWrapper>
  );
}

Lab110.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};
export default Lab110;
