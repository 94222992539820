import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useWidgetDispatch, useWidgetSelector } from 'common/hooks';
import { setLanguage, setIsMobile } from 'widgets/onboarding/module';
import NotFound from 'widgets/onboarding/common/NotFound';

import { primaryLanguage } from 'languages/config';

import { Wrapper, NavBar } from './elements';

export default function Index(): React.ReactElement {
  const { language = primaryLanguage } = useParams();
  const dispatch = useWidgetDispatch();
  const isNotFound = useWidgetSelector(state => state.common.isNotFound);
  const mediaQuery = window.matchMedia('(min-width: 770px)');

  const handleResize = (query: MediaQueryList | MediaQueryListEvent) => {
    if (query.matches) {
      dispatch(setIsMobile(false));
    } else {
      dispatch(setIsMobile(true));
    }
  };

  handleResize(mediaQuery);

  useEffect(() => {
    dispatch(setLanguage(language));
    document.documentElement.lang = language;
  }, [language, dispatch]);

  useEffect(() => {
    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  if (isNotFound) {
    return (
      <Wrapper>
        <NavBar />
        <NotFound />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <NavBar />
      <Outlet />
    </Wrapper>
  );
}
