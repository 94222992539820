import { Championship } from 'widgets/onboarding/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  filterSelected,
  setHasFilter,
  setNotFound,
  setIndicator,
} from 'widgets/onboarding/module';
import { databaseProvider } from 'providers/Database/reduxIndex';
import { ClientConfig } from 'configs/types';

const database = databaseProvider({
  fetch: fetch,
  baseUrl: (process.env.CONFIG as unknown as ClientConfig).firebase.databaseURL,
  ccmUrl: (process.env.CONFIG as unknown as ClientConfig).ccm.URL,
  language: 'no',
});

function getChampionship(
  championshipKey: string
): Promise<{ championship: Championship }> {
  return database
    .getChampionship(championshipKey)
    .then(championship => ({ championship }));
}

export const fetchChampionshipData = createAsyncThunk(
  'widgetChampionship/fetchChampionship',
  async (
    championshipKey: string,
    { dispatch }
  ): Promise<Championship | null> => {
    const { championship } = await getChampionship(championshipKey);

    if (!championship) {
      dispatch(setNotFound());

      return null;
    }

    dispatch(setIndicator(championship.indicator));

    const hasMoreThanOneSeries = championship.series.length > 1;

    dispatch(setHasFilter(hasMoreThanOneSeries));

    if (hasMoreThanOneSeries) {
      dispatch(filterSelected('allGroups'));
    }

    return championship;
  }
);
