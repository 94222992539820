import { PayloadAction } from '@reduxjs/toolkit';
import { WidgetCommonState } from './types';

export const filterSelected = (
  state: WidgetCommonState,
  action: PayloadAction<string>
): void => {
  state.selectedFilter = action.payload;
  state.filterExpanded = false;
  state.graphOffset = 0;
};

export const toggleFilter = (state: WidgetCommonState): void => {
  state.filterExpanded = !state.filterExpanded;
};

export const arrowClicked = (
  state: WidgetCommonState,
  action: PayloadAction<number>
): void => {
  state.graphOffset = action.payload;
};

export const setHasFilter = (
  state: WidgetCommonState,
  action: PayloadAction<boolean>
): void => {
  state.hasFilter = action.payload;
};

export const setLanguage = (
  state: WidgetCommonState,
  action: PayloadAction<string>
): void => {
  state.language = action.payload;
};

export const setIndicator = (
  state: WidgetCommonState,
  action: PayloadAction<string>
): void => {
  state.indicator = action.payload;
};

export const setIsMobile = (
  state: WidgetCommonState,
  action: PayloadAction<boolean>
): void => {
  state.isMobile = action.payload;
};

export const setNotFound = (state: WidgetCommonState): void => {
  state.isNotFound = true;
};

export const showNavItemMenuClicked = (state: WidgetCommonState): void => {
  state.showNavLanguageMenu = !state.showNavLanguageMenu;
};
