export default {
  total_saving: {
    co2: 'Total kgCO\u2082e saved',
    points: 'Total points earned',
  },
  total_participants: 'Total participants',
  total_activities: 'Activities logged',
  all: 'All %{term}',
  series_terms: {
    series: 'series',
    company_size: 'company sizes',
    cities: 'cities',
    counties: 'counties',
    industries: 'industries',
  },
  campaign_terms: {
    campaigns: 'campaigns',
    schools: 'schools',
    companies: 'companies',
  },
  days_until_start:
    '%{smart_count} day until championship starts |||| %{smart_count} days until championship starts',
  now_over: 'This championship is now over',
  average: {
    co2: 'average kgCO\u2082e saved per participant',
    co2_and_participation:
      'average kgCO\u2082e saved per participant AND at least %{requiredParticipation}% participation reached',
    points: 'average points saved per participant',
  },
  not_reached_participation:
    'not yet reached %{requiredParticipation}% participation rate, which is required in order to win.',
};
