import styled from 'styled-components';

export const OuterWrapper = styled.div`
  display: inline-block;
`;

export const DotsWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
`;

type DotProps = {
  color?: string;
  theme: string;
  $isActive: boolean;
  $noPointer: boolean;
};

const defaultColors = {
  light: 'rgba(96,125,139)',
  dark: 'rgba(255,255,255)',
};

export const Dot = styled.li<DotProps>`
  background-color: ${props =>
    props.color ? props.color : defaultColors[props.theme]};
  opacity: ${props => (props.$isActive ? '100%' : '50%')};
  height: 8px;
  width: 8px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 12px 0 0;
  padding: 0;
  cursor: ${props => (props.$noPointer ? 'default' : 'pointer')};
`;
