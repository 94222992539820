import React from 'react';
import { useText, useFormatDate } from 'common/hooks';

import { DATE_FORMAT } from 'resources/constants';
import { getFullDaysDiff } from 'utils/common';

import { Details, Progress } from './elements';

type Props = {
  readonly startDatetime: number;
  readonly endDatetime: number;
  readonly startText: string;
  readonly endText: string;
};

export default function Duration({
  startDatetime,
  endDatetime,
  startText,
  endText,
}: Props): React.ReactElement {
  const t = useText();
  const formatDate = useFormatDate();
  const daysUntilStart = getFullDaysDiff(Date.now(), startDatetime);

  const percent = startDatetime
    ? ((Date.now() - startDatetime) * 100) / (endDatetime - startDatetime)
    : 101;

  return (
    <>
      {percent < 0 && (
        <Details>
          {t(startText, {
            smart_count: daysUntilStart,
          })}
        </Details>
      )}
      {percent >= 0 && percent <= 100 && (
        <Progress
          fromDate={formatDate(startDatetime, DATE_FORMAT.DAY_MONTH_TIME_SHORT)}
          toDate={formatDate(endDatetime, DATE_FORMAT.DAY_MONTH_TIME_SHORT)}
          percent={percent}
        />
      )}
      {percent > 100 && <Details>{t(endText)}</Details>}
    </>
  );
}
