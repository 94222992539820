/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as getData from './getData';

export const databaseProvider = ({ baseUrl, fetch, ccmUrl, language }) => {
  function fetchData(path) {
    return fetch(`${baseUrl}/${path}`).then(response => response.json());
  }

  return {
    async getCampaign(campaignId) {
      const oldCampaign = campaignId.charAt(0) === '-';

      if (!oldCampaign) {
        const response = await fetch(
          `${ccmUrl}/getCampaign?campaignId=${campaignId}&language=${language}`
        );
        const ccmCampaign = await response.json();

        if (response.status === 200) {
          return ccmCampaign;
        }
      }

      return getData.getCampaign(fetchData, campaignId).then(campaign => {
        if (!campaign) return null;

        const promises = [
          {
            key: 'organisation',
            promise: getData.getOrganisation(
              fetchData,
              campaign.organisationKey
            ),
          },
        ];

        if (campaign.tools.challengeKey) {
          promises.push({
            key: 'challenge',
            promise: getData.getChallenge(
              fetchData,
              campaign.tools.challengeKey
            ),
          });
        }

        return Promise.all(promises.map(p => p.promise)).then(values => {
          values.forEach((value, index) => {
            campaign[promises[index].key] = values[index];
          });

          return campaign;
        });
      });
    },
    async getCampaignByChallengeId(challengeId) {
      const oldCampaign = challengeId.charAt(0) === '-';

      if (!oldCampaign) {
        const response = await fetch(
          `${ccmUrl}/getCampaign?campaignId=${challengeId}&language=${language}`
        );
        const ccmCampaign = await response.json();

        if (response.status === 200) {
          return ccmCampaign;
        }
      }

      return getData.getChallenge(fetchData, challengeId).then(challenge => {
        if (!challenge) return null;

        return getData
          .getCampaign(fetchData, challenge.campaignKey)
          .then(campaign => {
            if (!campaign) return null;

            campaign.challenge = challenge;

            const promises = [
              {
                key: 'organisation',
                promise: getData.getOrganisation(
                  fetchData,
                  campaign.organisationKey
                ),
              },
            ];

            return Promise.all(promises.map(p => p.promise)).then(values => {
              values.forEach((value, index) => {
                campaign[promises[index].key] = values[index];
              });

              return campaign;
            });
          });
      });
    },
    isAllowedEmailDomainForCampaign(domain, campaignId) {
      return getData
        .verifyDomainForCampaign(fetchData, domain, campaignId)
        .then(data => Boolean(data));
    },
    async getChampionship(championshipId) {
      const oldChampionship = championshipId.charAt(0) === '-';

      if (!oldChampionship) {
        const response = await fetch(
          `${ccmUrl}/getChampionship?championshipId=${championshipId}&language=${language}`
        );
        const ccmChampionship = await response.json();

        if (response.status === 200) {
          return ccmChampionship;
        }
      }

      return Promise.all([
        getData.getChampionshipConfig(fetchData, championshipId),
        getData.getChampionshipSeries(fetchData, championshipId),
        getData.getChampionshipCampaigns(fetchData, championshipId),
      ]).then(([config, series, campaignKeys]) => {
        if (!config) return null;

        return Promise.all(
          Object.keys(campaignKeys || {}).map(campaignId =>
            this.getCampaign(campaignId).then(campaign => {
              const seriesKey = Object.keys(series || {}).find(
                seriesId => series[seriesId].campaigns[campaignId]
              );

              return {
                ...campaign,
                series: seriesKey
                  ? { id: seriesKey, name: series[seriesKey].name }
                  : null,
              };
            })
          )
        ).then(campaigns => ({
          ...config,
          series: Object.keys(series || {}).map(seriesId => ({
            id: seriesId,
            name: series[seriesId].name,
          })),
          campaigns,
        }));
      });
    },
  };
};
