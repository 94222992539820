import { createSlice } from '@reduxjs/toolkit';
import { WidgetChampionshipState } from './types';
import * as thunks from './thunks';

const initialState: WidgetChampionshipState = {
  isLoading: true,
  championship: null,
};

export const { fetchChampionshipData } = thunks;

export const widgetChampionshipSlice = createSlice({
  name: 'widgetChampionship',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchChampionshipData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchChampionshipData.fulfilled, (state, action) => {
        state.championship = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchChampionshipData.rejected, state => {
        state.isLoading = false;
      });
  },
});

export default widgetChampionshipSlice.reducer;
