import { getDatabase, ref, child, get } from 'firebase/database';
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign } from 'widgets/onboarding/types';

const dbRef = ref(getDatabase());

export const firebaseApi = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getChampionshipParticipantCounts: build.query({
      async queryFn(args: { campaigns: Campaign[]; level?: string }) {
        try {
          const { campaigns, level = 'total' } = args;
          const participantCounts = Object.assign(
            {},
            ...(await Promise.all(
              campaigns.map(async (campaign: Campaign) => ({
                [campaign.id]:
                  (await (
                    await get(
                      child(
                        dbRef,
                        `campaigns/participantCounts/${campaign.id}/${level}`
                      )
                    )
                  ).val()) || (level === 'total' ? 0 : {}),
              }))
            ))
          );

          return { data: participantCounts };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getParticipantCounts: build.query({
      async queryFn(args) {
        try {
          const { campaignId, level = 'total' } = args;
          const result = await get(
            child(dbRef, `campaigns/participantCounts/${campaignId}/${level}`)
          );

          return { data: result };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getChampionshipTotalStats: build.query({
      async queryFn(args: { campaigns: Campaign[]; level?: string }) {
        try {
          const { campaigns, level = 'challenges' } = args;
          const totalStats = Object.assign(
            {},
            ...(await Promise.all(
              campaigns.map(async (campaign: Campaign) => ({
                [campaign.id]:
                  (await (
                    await get(
                      child(
                        dbRef,
                        `challenges/totalStats/${level}/${campaign.challenge.id}`
                      )
                    )
                  ).val()) || {},
              }))
            ))
          );

          return { data: totalStats };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getTotalStats: build.query({
      async queryFn(args) {
        try {
          const { challengeId, level = 'challenges' } = args;
          const result = await get(
            child(dbRef, `challenges/totalStats/${level}/${challengeId}`)
          );

          return { data: result };
        } catch (e) {
          return { error: e };
        }
      },
    }),
  }),
});

export const {
  useGetChampionshipParticipantCountsQuery,
  useGetChampionshipTotalStatsQuery,
  useGetParticipantCountsQuery,
  useGetTotalStatsQuery,
} = firebaseApi;
