import styled from 'styled-components';
import Lab113B from 'dc/Lab113B';
import CarMob01 from 'dc/CarMob01';

export const DesktopWrapper = styled.div`
  display: flex;
  @media (max-width: 770px) {
    display: none;
  }
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 770px) {
    display: flex;
  }
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 17px;
  background-color: #fff;
`;

type TotalWrapperProps = {
  hasFilter?: boolean;
};

export const TotalWrapper = styled.div<TotalWrapperProps>`
  display: flex;
  align-content: center;
  justify-content: ${props => (props.hasFilter ? 'flex-start' : 'center')};
  margin-top: 12px;
  margin-bottom: 19px;
  width: 100%;
`;
export const Total = styled.div`
  padding: 25px;
  flex-basis: 288px;
  margin-right: 24px;
`;

export const TotalMobile = styled.div`
  padding: 25px 25px 0 25px;
`;
export const Label = styled(Lab113B)`
  & > span {
    color: #546e7a;
  }
`;

export const Carousel = styled(CarMob01)`
  margin-bottom: 10px;
`;
