import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, DotOne, DotTwo, DotThree } from './elements';

function Load05(props) {
  return (
    <Wrapper className={props.className}>
      <DotOne color={props.color} />
      <DotTwo color={props.color} />
      <DotThree color={props.color} />
    </Wrapper>
  );
}

Load05.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Load05;
