import { select } from 'd3-selection';
import 'd3-transition';
import { scaleLinear } from 'd3-scale';
import { getIndicatorConfig, formatNumber } from 'utils/common';

import icons from './icons';

import { INDICATOR } from 'resources/constants';

const d3Chart = {};

const LINE_OFFSET = 72;
const NAME_OFFSET = 60;

const TRANSITION_DURATION = 500;

// eslint-disable-next-line max-statements
d3Chart.create = (el, props) => {
  const BAR_MARGIN = props.barSize.margin;
  const svg = select(el).append('svg');

  const defs = svg.append('defs');

  const gradientRight = defs.append('linearGradient').attr('id', 'gradRight');

  gradientRight
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', props.backgroundColor || 'white')
    .attr('stop-opacity', 0);

  gradientRight
    .append('stop')
    .attr('offset', '40%')
    .attr('stop-color', props.backgroundColor || 'white')
    .attr('stop-opacity', 1);

  const gradientLeft = defs.append('linearGradient').attr('id', 'gradLeft');

  gradientLeft
    .append('stop')
    .attr('offset', '60%')
    .attr('stop-color', props.backgroundColor || 'white')
    .attr('stop-opacity', 1);

  gradientLeft
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', props.backgroundColor || 'white')
    .attr('stop-opacity', 0);

  svg.append('g').attr('id', 'bars');
  svg.append('g').attr('id', 'text');
  svg.append('g').attr('id', 'yAxis');

  svg
    .append('rect')
    .attr('id', 'leftCover')
    .attr('x', -10)
    .attr('y', 0)
    .attr('height', props.height)
    .attr('width', BAR_MARGIN * 1.5)
    .attr('fill', `url('#gradLeft')`);

  svg
    .append('rect')
    .attr('id', 'rightCover')
    .attr('x', props.width - BAR_MARGIN * 1.5)
    .attr('y', 0)
    .attr('height', props.height)
    .attr('width', BAR_MARGIN * 1.5)
    .attr('fill', `url('#gradRight')`);

  d3Chart.update(el, props);
};

// eslint-disable-next-line max-statements
d3Chart.update = (el, props) => {
  const BAR_WIDTH = props.barSize.width;
  const BAR_MARGIN = props.barSize.margin;
  const BAR_TOTAL_WIDTH = BAR_WIDTH + 2 * BAR_MARGIN;
  const LEFT_OFFSET = BAR_MARGIN;
  const svg = select(el).select('svg');
  const { data, height, width, offsetNumber } = props;

  const highestScore = Math.max(...data.map(d => d.value), Number.EPSILON);

  const yScale = scaleLinear()
    .domain([0, highestScore])
    .range([height - LINE_OFFSET, 60]);

  // Resize svg-canvas
  svg.attr('width', width).attr('height', height);

  // Move the right gradient
  svg.select('#rightCover').attr('x', width - BAR_MARGIN * 1.5);

  // TEXT
  const textPieces = svg
    .select('#text')
    .selectAll('foreignObject.text')
    .data(data, d => d.id);

  const textEntered = textPieces.enter();

  textEntered
    .append('foreignObject')
    .attr(
      'x',
      (_, index) =>
        BAR_TOTAL_WIDTH * (index - offsetNumber) +
        BAR_MARGIN +
        LEFT_OFFSET +
        0.5 * BAR_WIDTH -
        0.5 * BAR_TOTAL_WIDTH
    )
    .attr('y', height - NAME_OFFSET)
    .attr('width', BAR_TOTAL_WIDTH)
    .attr('height', '54px')
    .attr('class', 'text')
    .append('xhtml:div')
    .attr('class', 'text')
    .style('text-align', 'center')
    .style('color', 'rgb(84, 110, 122)')
    .style('font-size', '14px')
    .style('line-height', '18px')
    .style('overflow-wrap', 'break-word')
    .attr('xmlns', 'http://www.w3.org/1999/xhtml')
    .attr('height', '100%')
    .attr('overflow', 'auto')
    .text(d => d.text);

  const textTransition = textPieces.transition().duration(TRANSITION_DURATION);

  textTransition
    .attr(
      'x',
      (_, index) =>
        BAR_TOTAL_WIDTH * (index - offsetNumber) +
        BAR_MARGIN +
        LEFT_OFFSET +
        0.5 * BAR_WIDTH -
        0.5 * BAR_TOTAL_WIDTH
    )
    .attr('y', height - NAME_OFFSET)
    .select('div')
    .text(d => d.text);

  textPieces.exit().remove();

  // BARS
  const rects = svg
    .select('#bars')
    .selectAll('g')
    .data(data, d => d.id);

  const entered = rects.enter().append('g').attr('class', 'bars');

  // Co2 value
  entered
    .append('text')
    .attr('class', 'value')
    .attr(
      'x',
      (d, i) =>
        BAR_TOTAL_WIDTH * (i - offsetNumber) +
        BAR_MARGIN +
        0.5 * BAR_WIDTH +
        LEFT_OFFSET
    )
    .attr('y', height - LINE_OFFSET - 8)
    .text(d =>
      formatNumber(d.value, {
        locale: props.locale || 'nb-NO',
        precision: props.indicator === INDICATOR.co2 ? 1 : 0,
      })
    );

  // Leaf
  entered
    .append('svg')
    .attr('class', 'icon')
    .attr('height', 24)
    .attr('width', 24)
    .attr('viewBox', '0 0 24 24')
    .attr(
      'x',
      (d, i) =>
        BAR_TOTAL_WIDTH * (i - offsetNumber) +
        BAR_TOTAL_WIDTH * 0.5 -
        12 +
        LEFT_OFFSET
    )
    .attr('y', height - LINE_OFFSET - 30)
    .append('path')
    .attr('d', icons[props.indicator || INDICATOR.co2])
    .attr('fill', getIndicatorConfig(props.indicator).color);

  // Bar
  entered
    .append('rect')
    .attr('class', 'bar')
    .attr('rx', 2)
    .attr(
      'x',
      (d, i) => BAR_TOTAL_WIDTH * (i - offsetNumber) + BAR_MARGIN + LEFT_OFFSET
    )
    .attr('y', height - LINE_OFFSET)
    .attr('height', 0)
    .attr('width', BAR_WIDTH)
    .attr('fill', d => d.color);

  // Remove unnecessary rectangles
  rects.exit().remove();

  const mergedSelection = entered.merge(rects);

  // Transition the y position after x position
  const trans = mergedSelection.transition().duration(TRANSITION_DURATION);

  // Bar
  trans
    .select('rect.bar')
    .attr(
      'x',
      (d, i) => BAR_TOTAL_WIDTH * (i - offsetNumber) + BAR_MARGIN + LEFT_OFFSET
    )
    .attr('y', d => yScale(d.value))
    .attr('height', d => height - yScale(d.value) - LINE_OFFSET)
    .attr('width', BAR_WIDTH)
    .attr('fill', d => d.color);

  // Leaf
  trans
    .select('svg')
    .attr(
      'x',
      (d, i) =>
        BAR_TOTAL_WIDTH * (i - offsetNumber) +
        BAR_TOTAL_WIDTH * 0.5 -
        12 +
        LEFT_OFFSET
    )
    .attr('y', d => yScale(d.value) - 24 - 30)
    .attr('opacity', 1);

  // CO2 value
  trans
    .select('text.value')
    .attr(
      'x',
      (d, i) =>
        BAR_TOTAL_WIDTH * (i - offsetNumber) +
        BAR_MARGIN +
        0.5 * BAR_WIDTH +
        LEFT_OFFSET
    )
    .attr('y', d => yScale(d.value) - 8)
    .text(d =>
      formatNumber(d.value, {
        locale: props.locale || 'nb-NO',
        precision: props.indicator === INDICATOR.co2 ? 1 : 0,
      })
    );
};

d3Chart.destroy = el => {
  select(el).select('svg').remove();
};

export default d3Chart;
