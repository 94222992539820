import React from 'react';
import { useWidgetSelector } from 'common/hooks';
import { selectTotalStatsBySectionSelected } from 'widgets/onboarding/widgets/challenge/selectors';
import {
  useGetParticipantCountsQuery,
  useGetTotalStatsQuery,
} from 'widgets/onboarding/module/queries';

import StatsComponent from 'widgets/onboarding/common/Stats';

type Props = {
  readonly className?: string;
};

export default function Stats({ className }: Props): React.ReactElement {
  const campaign = useWidgetSelector(state => state.campaign.campaign);
  const queryOptions = {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true,
    skip: false,
    refetchOnFocus: true,
  };

  const participantCounts = {
    total:
      useGetParticipantCountsQuery(
        {
          campaignId: campaign.id,
          level: 'total',
        },
        queryOptions
      )?.data?.val() ?? 0,
    sections:
      useGetParticipantCountsQuery(
        {
          campaignId: campaign.id,
          level: 'sections',
        },
        queryOptions
      )?.data?.val() ?? {},
    teams:
      useGetParticipantCountsQuery(
        {
          campaignId: campaign.id,
          level: 'teams',
        },
        queryOptions
      )?.data?.val() ?? {},
  };

  const toteStats = {
    challenge:
      useGetTotalStatsQuery(
        {
          challengeId: campaign.challenge.id,
          level: 'challenges',
        },
        queryOptions
      )?.data?.val() ?? 0,
    sections:
      useGetTotalStatsQuery(
        {
          challengeId: campaign.challenge.id,
          level: 'sections',
        },
        queryOptions
      )?.data?.val() ?? {},
    teams:
      useGetTotalStatsQuery(
        {
          challengeId: campaign.challenge.id,
          level: 'teams',
        },
        queryOptions
      )?.data?.val() ?? {},
  };

  const totalStats = useWidgetSelector(state =>
    selectTotalStatsBySectionSelected(state, participantCounts, toteStats)
  );

  return <StatsComponent className={className} totalStats={totalStats} />;
}
