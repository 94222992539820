import React from 'react';
import { Wrapper, BoldPosition, Position, Label } from './elements';

type Props = {
  readonly className?: string;
  readonly value: string;
  readonly name: string;
  readonly position: number;
  readonly percent: number;
  readonly bold?: boolean;
  readonly icon?: string;
  readonly color: string;
};

export default function LBCo2b({
  className,
  value,
  name,
  position,
  percent,
  bold,
  icon = 'icon-leaf',
  color,
}: Props): React.ReactElement {
  return (
    <Wrapper className={className}>
      {bold ? (
        <BoldPosition>{position}</BoldPosition>
      ) : (
        <Position>{position}</Position>
      )}
      <Label
        name={name}
        points={value}
        icon={icon}
        iconColor={color}
        percent={percent}
      />
    </Wrapper>
  );
}
