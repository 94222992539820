import React from 'react';

export default class ChartWrapper extends React.Component {
  constructor() {
    super();
    this.container = React.createRef();
  }

  reRender() {
    if (!this.container.current) return;
    this.props.chart.update(
      this.container.current,
      Object.assign(this.getChartState(), {
        data: this.props.data,
        width: this.container.current.offsetWidth,
        height: this.container.current.offsetHeight,
        margin: this.margin,
        id: this.props.graphID,
      }),
      this.props.formatting
    );
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.reRender());
    this.props.chart.create(
      this.container.current,
      Object.assign(this.getChartState(), {
        data: this.props.data,
        width: this.container.current.offsetWidth,
        height: this.container.current.offsetHeight,
        margin: this.margin,
        id: this.props.graphID,
      })
    );
    this.initialUpdate = setTimeout(() => {
      this.reRender();
    }, 100);
  }

  componentDidUpdate() {
    this.reRender();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.reRender());
    clearTimeout(this.initialUpdate);
    this.props.chart.destroy(this.props.graphID);
  }

  getChartState() {
    return { ...this.props };
  }

  render() {
    return (
      <div
        className={`Chart ${this.props.className}`}
        id={`chartDiv${this.props.graphID}`}
        ref={this.container}
        style={{ height: this.props.height }}
      />
    );
  }
}
