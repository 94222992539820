import React from 'react';
import { useWidgetSelector } from 'common/hooks';
import Duration from 'widgets/onboarding/common/Layout/Duration';

import { Wrapper, Name } from './elements';

import dayjs from 'dayjs';

type Props = {
  readonly className?: string;
};

export default function Header({ className }: Props): React.ReactElement {
  const championship = useWidgetSelector(
    state => state.championship.championship
  );
  const isMobile = useWidgetSelector(state => state.common.isMobile);
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);
  const startDatetime = dayjs(championship.startDate).add(1, 'm').valueOf();
  const endDatetime = dayjs(championship.endDate)
    .add(23, 'h')
    .add(59, 'm')
    .valueOf();

  return (
    <Wrapper className={className} hasFilter={hasFilter} isMobile={isMobile}>
      <Name isMobile={isMobile}>{championship.name}</Name>
      <Duration
        startText={'widgets.championship.days_until_start'}
        endText={'widgets.championship.now_over'}
        startDatetime={startDatetime}
        endDatetime={endDatetime}
      />
    </Wrapper>
  );
}
