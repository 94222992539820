import React from 'react';
import NotFoundComponent from 'dc/NotFound';
import { useText } from 'common/hooks';

export default function NotFound(): React.ReactElement {
  const t = useText();

  return (
    <NotFoundComponent
      title={t('appContainer.not_found.title')}
      body={t('appContainer.not_found.body')}
      imageSrc={`https://static.ducky.eco/images/trophy_investigate.svg`}
    />
  );
}
