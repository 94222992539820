export default {
  ModLogin: {
    email: 'E-mail',
    password: 'Passord',
    register: 'Registrer',
    account: 'konto',
    login: 'Logg inn',
    send_magic_link: 'SEND MEG INNLOGGINGSLINKEN',
    name: 'Navn',
    send: 'send',
    super: 'Supert',
    or: 'eller',
  },
  ModTerms: {
    privacy_policy: 'Personvernerklæring',
    last_updated: 'Sist oppdatert %{date}',
    accept: 'Aksepter',
    decline: 'Avslå',
    delete: 'SLETT PROFIL',
    go_back: 'TILBAKE',
    are_you_sure: 'Er du sikker?',
    are_you_sure_body:
      'Ved å avslå de oppdaterte vilkårene blir kontoen din og alle tilknyttede data slettet.',
    terms_updated: 'Vi har oppdatert våre vilkår',
    terms_of_use:
      'MARKDOWN:[vilkårene for bruk og personvernreglene](https://www.ducky.eco/terms)',
  },
  ModRegister: {
    i_have_read: 'Jeg har lest og forstått',
    terms_of_use: 'vilkårene for bruk og personvernreglene',
    or: 'eller',
  },
  NavBar: {
    support: 'Brukerstøtte',
    products: 'Produkter',
    find_tools: 'Finn verktøyene dine',
    logIn: 'Logg inn',
    menu: 'Meny',
    register: 'Registrer konto',
    contact_us: 'Kontakt oss',
    your_tools: 'Dine verktøyene',
    faq: 'Ofte stilte spørsmål',
    ProMen2: {
      active_since: 'Aktiv siden',
      log_out: 'Logg ut',
      profile: 'Rediger din profil',
    },
  },
  network_status_notification: 'Du er offline, sjekk internett forbindelsen!',
  error_boundary: {
    title: 'Oisann! Noe gikk galt',
    body: 'Våre detektiver kunne ikke finne ut av det',
    button_text: 'prøv igjen',
  },
  not_found: {
    title: 'Vi fant ikke denne siden',
    body: '',
    button_text: 'Gå tilbake til tidslinjen',
  },
};
