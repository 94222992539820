import appContainer from '../appContainer';
import campaign from '../campaign';
import challenge from './challenge';
import championship from './championship';

export default {
  locale: 'en',
  fullLocale: 'en-GB',
  format: {
    locale: 'en',
    weekStart: 1,
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
  },
  phrases: {
    appContainer,
    campaign,
    widgets: {
      challenge,
      championship,
      navbar: {
        powered_by: 'Powered by Ducky',
      },
    },
  },
};
