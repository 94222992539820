import styled, { css } from 'styled-components';
import { Typ60 } from 'dc/Typ';

type WrapperProps = {
  isMobile?: boolean;
  hasFilter?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.hasFilter || props.isMobile ? 'flex-start' : 'center'};
  width: 100%;
  margin-right: 16px;
`;

type MobileProps = {
  isMobile?: boolean;
};

export const Name = styled(Typ60)<MobileProps>`
  margin-bottom: 16px;
  ${props =>
    props.isMobile &&
    css`
      font-size: 20px;
      line-height: 24px;
    `}
`;
