import React from 'react';
import { useText, useWidgetSelector } from 'common/hooks';

import LegendComponent from 'widgets/onboarding/common/Legend';

type Props = {
  readonly className?: string;
};

export default function Legend({ className }: Props): React.ReactElement {
  const t = useText();
  const campaign = useWidgetSelector(state => state.campaign.campaign);

  return (
    <LegendComponent
      className={className}
      mainText={t(`widgets.championship.average.${campaign.indicator}`)}
    />
  );
}
