import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import widgetCommonReducer from './module';
import widgetChampionshipReducer from './widgets/championship/module';
import { firebaseApi } from 'widgets/onboarding/module/queries';
import widgetCampaignReducer from './widgets/challenge/module';

export const store = configureStore({
  reducer: {
    common: widgetCommonReducer,
    championship: widgetChampionshipReducer,
    campaign: widgetCampaignReducer,
    [firebaseApi.reducerPath]: firebaseApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(firebaseApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {common: CommonState, championship: ChampionshipState, campaign: CampaignState}
export type AppDispatch = typeof store.dispatch;
