import React from 'react';
import { Store } from '@reduxjs/toolkit';
import { RootState } from '../controller';
import Container from 'widgets/onboarding/common/ContainerRedux';
import Widget from './Widget';

type Props = {
  readonly store: Store<RootState>;
};

export default function App({ store }: Props): React.ReactElement {
  return (
    <Container store={store}>
      <Widget />
    </Container>
  );
}
