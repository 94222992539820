import React from 'react';
import { useText, useWidgetSelector } from 'common/hooks';

import LegendComponent from 'widgets/onboarding/common/Legend';

type Props = {
  readonly className?: string;
};

export default function Legend({ className }: Props): React.ReactElement {
  const t = useText();
  const championship = useWidgetSelector(
    state => state.championship.championship
  );

  return (
    <LegendComponent
      className={className}
      mainText={t(`widgets.championship.average.${championship.indicator}`)}
      secondaryText={
        championship.winningParticipationPercentage > 0 &&
        t('widgets.championship.average.co2_and_participation', {
          requiredParticipation: championship.winningParticipationPercentage,
        })
      }
    />
  );
}
