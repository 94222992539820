import React from 'react';

import {
  DesktopWrapper,
  MobileWrapper,
  TotalWrapper,
  Total,
  TotalMobile,
  Label,
  Carousel,
} from './elements';
import { SwiperSlide } from 'swiper/react';

import { useText, useWidgetSelector } from 'common/hooks';
import { getIndicator, getIndicatorConfig } from 'utils/common';

type Props = {
  readonly className?: string;
  readonly totalStats: {
    co2: number;
    points: number;
    activities: number;
    participants: number;
  };
};

export default function Stats({
  className,
  totalStats,
}: Props): React.ReactElement {
  const t = useText();
  const indicator = useWidgetSelector(state => state.common.indicator);
  const hasFilter = useWidgetSelector(state => state.common.hasFilter);

  const value = getIndicator(totalStats, indicator);
  const { icon, color } = getIndicatorConfig(indicator);

  return (
    <>
      <DesktopWrapper className={className}>
        <TotalWrapper hasFilter={hasFilter}>
          <Total>
            <Label
              caption={t(`widgets.championship.total_saving.${indicator}`)}
              icon={icon}
              color={color}
              number={value}
              locale={t.fullLocale}
            />
          </Total>
          <Total>
            <Label
              caption={t(`widgets.championship.total_activities`)}
              icon={'icon-check'}
              color={'#007079'}
              number={totalStats.activities}
              locale={t.fullLocale}
            />
          </Total>
          <Total>
            <Label
              caption={t('widgets.championship.total_participants')}
              icon={'icon-people'}
              color={'#E0487B'}
              number={totalStats.participants}
              locale={t.fullLocale}
            />
          </Total>
        </TotalWrapper>
      </DesktopWrapper>
      <MobileWrapper className={className}>
        <Carousel>
          <SwiperSlide>
            <TotalMobile>
              <Label
                caption={t(`widgets.championship.total_saving.${indicator}`)}
                icon={icon}
                color={color}
                number={value}
                locale={t.fullLocale}
              />
            </TotalMobile>
          </SwiperSlide>
          <SwiperSlide>
            <TotalMobile>
              <Label
                caption={t(`widgets.championship.total_activities`)}
                icon={'icon-check'}
                color={'#007079'}
                number={totalStats.activities}
                locale={t.fullLocale}
              />
            </TotalMobile>
          </SwiperSlide>
          <SwiperSlide>
            <TotalMobile>
              <Label
                caption={t('widgets.championship.total_participants')}
                icon={'icon-people'}
                color={'#E0487B'}
                number={totalStats.participants}
                locale={t.fullLocale}
              />
            </TotalMobile>
          </SwiperSlide>
        </Carousel>
      </MobileWrapper>
    </>
  );
}
