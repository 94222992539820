import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { store } from './controller';
import App from './App';

function AppWithCallbackAfterRender() {
  useEffect(() => {
    document.getElementById('loader').classList.add('loaded');
  });

  return <App store={store} />;
}

const container = document.querySelector('#app');
const root = createRoot(container);

root.render(<AppWithCallbackAfterRender />);
