import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper } from './elements';
import ProB01 from 'dc/ProB01';
import Spa08 from 'dc/Spa08';
import LBLab1 from 'dc/LBLab1';

function LBLab2(props) {
  return (
    <Wrapper className={props.className}>
      <LBLab1
        icon={props.icon}
        name={props.name}
        points={props.points}
        iconColor={props.iconColor}
      />
      <Spa08 />
      <ProB01
        color={props.progressColor || '#00ab97'}
        percent={props.percent}
      />
    </Wrapper>
  );
}

LBLab2.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  percent: PropTypes.number,
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  progressColor: PropTypes.string,
  iconColor: PropTypes.string,
};

export default LBLab2;
